<template>
<div>
  <Header/>
  <b-container
    style="max-width:90%; padding-top:70px; "
  >
    <b-row>
      <b-col >
        <div
          class="image-input mx-auto"
        >
          <div
            class="image-input__field"
            :class="{'over': isDragOver}"
            @dragover.prevent="onDrag('over')"
            @dragleave="onDrag('leave')"
            @drop.stop="onDrop"
          >
            <input
              type="file"
              title
            >
            <p>画像をドラッグ＆ドロップ
              <br>してください。
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          @click="uploadS3Image()"
        >
          写真アップロード
        </b-button>
        <b>
        {{ uploadFileName.length }}枚
        </b>
      </b-col>
      <b-col
        style="text-align:right;"
      >
        <b-button
          variant="success"
          @click="postEndtext"
          :disabled="endTextFlag"
        >
          エンドファイル送信
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span
          v-for="(fileName,fileNameIndex) in uploadFileName"
          :key="fileNameIndex"
        >
          {{fileName}}
        </span>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import Header from '../../components/Header.vue'
import aws from 'aws-sdk'

export default {
  title:'物件写真アップロード',
  components:{
    Header
  },
  data() {
    return {
      isDragOver: false,
      drag:false,
      s3:'',
      uploadFiles:[],
      uploadFileName:[],
      messageType:'',
      s3Bucket:'',
      s3BucketFolder:'before_resize/',
      endTextFlag:false
    }
  },
  created() {
    this.s3 = new aws.S3({
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_AWS_S3_REGION
    });
    this.s3Bucket = process.env.VUE_APP_AWS_S3_BUCKET
  },
  methods:{
    //エンドテキスト送信
    postEndtext() {
      try {
        var endFile = new File([""], "end.txt",{type: "text/plain"});
        this.s3.putObject({
          Bucket: this.s3Bucket,
          Key: this.s3BucketFolder + endFile.name,
          Body: endFile,
          ContentType:"text/plain"
        }).promise()
        .then(response => {
          console.log(response)
          this.$swal({
            title: 'エンドファイルアップロードが完了しました。',
            toast: true,
            icon:'success',
            position: 'top-end',
            showConfirmButton: false,
          });
          this.uploadFiles = []
          this.uploadFileName = []
          this.endTextFlag = true
        })
        .catch(error => {
          throw error
        });
      } catch(error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          html:`エンドファイルアップロードエラー`,
          confirmButtonText:"閉じる"
        })
        return
      }
    },
    //画像ドラックオーバー時
    onDrag(type) {
      this.isDragOver = type === "over";
    },
    //ドラックアンドドロップで写真投稿時
    onDrop(event) {
      this.isDragOver = false;
      this.drag = true;
      this.notDate = ''
      const files = event.dataTransfer.files;
      for(let file of files) {
        if(file.type !== 'image/jpeg') {
          this.$swal({
            icon: 'error',
            html:'写真はjpegファイルでアップロードしてください',
            title:'写真登録エラー',
            confirmButtonText:"閉じる"
          })
          return
        }
        if(file.type.indexOf("image") !== 0) {
          this.$swal({
            icon: 'error',
            html:'画像ファイルをアップロードしてください',
            title:'写真登録エラー',
            confirmButtonText:"閉じる"
          })
          return
        }
        this.uploadFileName.push(file.name)
        this.uploadFiles.push(file)
      }
    },
  // s3への写真アップロード
  async uploadS3Image() {
    if(this.uploadFiles.length == 0) {
      this.$swal({
        icon: 'error',
        title:'写真がアップロードされていません。',
        confirmButtonText:"閉じる"
      })
      return
    } else {
      let count = 0
    this.$swal({
      title: 'アップロード中',
      html: `写真のアップロード中です。<br>
      ${count}/${this.uploadFiles.length}`,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
    });
    for(let uploadFile of this.uploadFiles) {
      try {
        await this.s3.putObject({
          Bucket: this.s3Bucket,
          Key: this.s3BucketFolder + uploadFile.name.replace(/\+|\]|\[|\?|%|#|\s+|\//gi,'_'),
          Body: uploadFile,
          ContentType:uploadFile.type
        }).promise()
        .then(response => {
          console.log(response)
          count ++
          this.$swal({
            title: 'アップロード中',
            html: `写真のアップロード中です。<br>
            ${count}/${this.uploadFiles.length}`,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
          });
          if(this.uploadFiles.length == count) {
            this.$swal({
              title: 'アップロード完了',
              html: `アップロードが完了しました。`,
              toast: true,
              icon:'success',
              position: 'top-end',
              showConfirmButton: false,
            });
            // this.uploadFiles = []
            // this.uploadFileName = []
          }
        })
        .catch(error => {
          throw error
        });
      } catch(error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          html:`写真アップロードエラー`,
          confirmButtonText:"閉じる"
        })
        return
        }
      }
    }

    },

  }
}
</script>
<style scoped>
  .custom-file-drop .custom-file-drop-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
}
.custom-file-drop {
    position: relative;
}
.custom-file-drop .custom-file-drop-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0.375rem 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.95);
    color: #909ead;
    cursor: pointer;
}

.formLabel {
  display: inline-block;
  width: 140px;
  text-align: right;
}

.image-input {
  background-color: #eee;
  width: 100%;
  height: 300px;

}
.image-input__field {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-input__field.over {
  background-color: #666;
}
.image-input__field > input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.image-input__field > p {
  color: #aaa;
  text-align: center;
}

</style>
